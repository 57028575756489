import React from "react";
import { Box } from "../components/box";
import { Content } from "../components/content";
import { ContentLinks } from "../components/content-links";
import { H1, H2, H3, Text } from "../components/text";
import { Hero } from "../components/hero";
import { Layout } from "../components/layout";
import { Seo } from "../components/seo";
import { TwoColumns } from "../components/two-columns";
import { InlineLink } from "../components/link";
import HeroImage from "../images/pages/about-us/hero.jpg";
import DogVaccinationImage from "../images/pages/about-us/dog-vaccination.jpg";
import WorkingEquineImage from "../images/pages/about-us/working_equine_1.jpg";
import SurgeryImage from "../images/pages/about-us/surgery.jpg";
import SEOImage from "../images/wvs-india-seo.jpg";
import WVSForeignAccounts2223 from "../files/pages/about-us/2022_2023_WVS INDIA CONSOLIDATED ACCOUNTS.pdf";
import WVSForeignAccounts2122 from "../files/pages/about-us/2021_2022_WVS INDIA CONSOLIDATED ACCOUNTS.pdf";
import WVSForeignAccounts2021 from "../files/pages/about-us/2020_2021_WVS INDIA CONSOLIDATED ACCOUNTS.pdf";
import WVSForeignAccounts1920 from "../files/pages/about-us/2019_2020_WVS INDIA CONSOLIDATED ACCOUNTS.pdf";
import WVSForeignAccounts1819 from "../files/pages/about-us/2018_2019_WVS INDIA CONSOLIDATED ACCOUNTS.pdf";
import WVSForeignAccounts1718 from "../files/pages/about-us/2017_2018_WVS INDIA CONSOLIDATED ACCOUNTS.pdf";
import WVSForeignAccounts1617 from "../files/pages/about-us/2016_2017_WVS INDIA CONSOLIDATED ACCOUNTS.pdf";

const Index = () => {
  return (
    <Layout>
      <Seo lang="en" title="About us" image={SEOImage} />
      <Hero image={HeroImage}>
        <H1>About us</H1>
      </Hero>
      <Content>
        <H2>WVS India International Centres</H2>
        <Text>
          WVS Centre in the Nilgiris, Tamil Nadu and the WVS Hicks Centre in Bardez,
          Goa are run by WVS India, a charitable Indian trust that was
          registered in 2010 on the firm foundation that IPAN (India Project for
          Animals and Nature) had already achieved in the animal welfare field.
          WVS India is recognised by the Animal Welfare Board of India (AWBI).
        </Text>
      </Content>
      <Content image={SurgeryImage}>
        <TwoColumns
          renderRight={
            <Box>
              <Text>
                The WVS ITCs focus in providing practical training courses for
                veterinarians, animal handlers, veterinary assistants and
                project managers in various aspects of animal health and
                welfare. A major emphasis of our work is in high quality
                spay/neuter (animal birth control) & rabies control–programs and
                in empowering veterinarians from all around India as well as
                from other countries to work in these projects to effectively
                and humanely control dog populations for the welfare of dogs and
                humans. We believe in improving animal welfare by veterinary
                skills development, inspiration and advice.
              </Text>
              <Text>
                Since the inauguration of the first WVS ITC in 2010, we have
                built an excellent reputation as a centre of excellence
                providing small animal surgery experience programmes in India.
                We are also known for our team of skilled and motivated animal
                handlers and veterinary assistants, who have been working in
                some of the biggest rabies control campaigns across India with
                Mission Rabies.
              </Text>
            </Box>
          }
        />
      </Content>

      <Content variant="secondary">
        <Text>
          We believe in encouraging veterinarians to commit in lifelong learning
          and continuous professional development to stay updated of the current
          trends, debates and techniques that are relevant and important for the
          improvement of animal health and welfare in their countries. We work
          together with veterinary colleges across the world to provide
          practical skills for vet students and programmes conducted by our 
          experienced vet trainers as resource
          personnel for veterinary colleges, especially in the developing
          countries (e.g. in Nepal). We aim to work together with government
          bodies, ensuring sustainability of our activities for the benefit of
          human and animal health and welfare, the One Health-concept.
        </Text>
      </Content>
      <Content>
        <H2>Mission Rabies Goa</H2>
        <Text>
          As is the case across most of India, Goa suffered the consequences of
          rabies circulating in the roaming dog population, with human deaths
          occurring where people did not get vaccinated following a bite from a
          rabid dog.
        </Text>
        <Text>
          There have been no human deaths from rabies for over two years due to
          a campaign by the Government of Goa to control the disease, in
          collaboration with Mission Rabies Goa. Over 4,00,000 doses of rabies
          vaccine have been delivered to dogs in Goa since 2013 in the largest
          continuous rabies control effort to have ever taken place in India.
          The campaign aims to get most dogs in the state vaccinated against
          rabies every year, to educate all children about the risk of rabies
          through lessons in schools and to increase testing for the disease
          through widespread public reporting of any possible rabies case across
          the state.
        </Text>
        <Text>
          The state of Goa was declared a RABIES CONTROLLED AREA through the
          notification issued by the Directorate of Animal Husbandry and
          Veterinary Services (AH&VS). The Government of Goa is the first state
          in India to make such a declaration for rabies control.
        </Text>
      </Content>
      <Content image={DogVaccinationImage}>
        <TwoColumns
          renderRight={
            <Box>
              <Text>
                Mission Rabies is a project of WVS India, focusing on three
                areas of work:
              </Text>
              <H3> Mass dog vaccination</H3>
              <Text>
                We use a combination of vaccination methods to try to reach as
                many dogs as possible for vaccination.
              </Text>
              <ul>
                <li>
                  Catch-vaccinate-release: These door-to-door teams are then
                  followed up by larger catch-vaccinate-release vaccination
                  teams using nets to catch and vaccinate dogs that cannot
                  otherwise be handled for vaccination. Once caught in the net,
                  each dog is vaccinated, marked with a non-toxic paint and
                  released unharmed. In this way we can immunise a high enough
                  proportion of dogs to eliminate rabies.
                </li>
                <li>
                  Door-to-door vaccination: First, our teams of two vaccinators
                  travel through a region going door-to-door, focusing on the
                  dogs which can be held for vaccination by hand, either by
                  their owner, a member of the community or by themselves. All
                  owners are provided with a vaccination certificate and a free
                  dog-collar to show that the dog is vaccinated.
                </li>
                <li>
                  Treatments: Our vaccination teams also serve to uphold the
                  welfare of roaming dogs by coordinating with local NGOs to
                  treat any dogs with wounds or injuries. Our teams help around
                  150 dogs per year that would otherwise suffer from chronic
                  injuries.
                </li>
              </ul>
              <H3>Community education about rabies</H3>
              <Text>
                A community education programme moves ahead of the vaccination
                teams to educate communities about the risk of rabies and how to
                stay safe around dogs.
              </Text>
              <Text>
                This includes a school education campaign which conducts classes
                for children in schools on a rotating basis. These lessons run
                for 15 – 30 minutes per class and include a presentation, quiz
                and theatre to engage with children and make them aware of
                life-saving knowledge about the risk of rabies. Around 1,400
                schools are visited each year, reaching around 170,000 children.
              </Text>
              <Text>
                The education programme also delivers sessions to community
                groups and workers unions to ensure that rabies is top of
                people’s concerns following any dog bite and to encourage the
                community to help get as many dogs vaccinated as possible when
                the vaccination teams come to their area.
              </Text>
              <H3>Enhanced rabies surveillance</H3>
              <Text>
                The community have an essential role in reporting any dog seen
                with possible signs of rabies to the Goa Rabies Hotline (
                <InlineLink href="tel:7744029586">7744029586</InlineLink>
                ).
              </Text>
              <Text>Please ensure you report any dog showing signs of:</Text>
              <ul>
                <li>Sudden death (including dead dogs you see in the road) </li>
                <li>Abnormal aggression – biting other dogs and people</li>
                <li>Neurological signs (walking as if drunk) </li>
                <li>Saliva drooling from the mouth</li>
              </ul>
            </Box>
          }
        />
      </Content>
      <Content>
        <H2>Working equine welfare clinics </H2>
        <Text>
          Working donkeys are the lifeblood of rural economies in many
          developing countries, serving important roles in transportation and
          labour. Due to lack of access to veterinary care and extremely
          challenging work environments the donkeys are often subject to major
          welfare issues.
        </Text>
        <Text>
          In India, donkeys are usually used for carrying sand from river bed,
          transporting wood and other goods along hilly terrains and also for
          helping dhobis to carry washed and unwashed clothes. Poor condition of
          the roads they travel, the heavy burdens they bear, the makeshift
          harnesses, combined with inadequate feed and medical attention
          contribute to a life of misery for many working donkeys.
        </Text>
      </Content>
      <Content variant="secondary">
        <Text>
          Many unconventional techniques are carried out by the owners
          themselves in an attempt to help the donkeys, but often these
          practices cause more pain and discomfort than good. Applying hot iron
          branding to lame and injured legs to facilitate walking, slitting of
          nostrils to improve breathing while going uphill, tipping of ear to
          remove infected blood when the donkey is suffering some illness or if
          he/she had a snake bite, ear cropping for identification and
          beautification are some of the cruelties inflicted upon donkeys by
          their owners out of sheer ignorance.
        </Text>
        <Text>
          These harmful practices could be prevented with improved access to
          veterinary care and animal health knowledge, raising awareness of the
          donkeys’ welfare and building a good rapport with the owners. This is
          what the WVS India working equine welfare project is working on. We
          often also include veterinary students to attend our clinics to
          provide them exposure in the welfare of working animals.
        </Text>
      </Content>
      <Content image={WorkingEquineImage}>
        <TwoColumns
          renderLeft={
            <Box>
              <Text>
                Tourist riding horses in popular tourist destinations such as in
                Ooty or Kodaikanal or Mysore are another group of working
                equines that often don’t have access to timely veterinary care.
                WVS India working equine monthly clinics also provide routine
                veterinary care, such as deworming, tetanus vaccinations, hoof
                trimming and teeth rasping for these horses. Stallions are
                surgically castrated by WVS India equine team to make them
                easier to handle. As part of this work with the tourist riding
                horses, we have also arranged specialist farriery skills
                workshops for the horse owners, teaching them some important
                basics of good routine hoof care.
              </Text>
              <Text>
                In Ooty the WVS India equine team also attends to emergencies,
                such as colic due to eating plastic rubbish from the roadside
                rubbish bins or traumatic injuries due to road traffic
                accidents. These are very common since many of these horses
                don’t have proper stables or any kind of sheds where to stay
                when not in use but they are left to roam on the roadsides,
                falling often victim of traffic accidents and gradually losing
                condition because of inadequate feeding. Along with the Nilgiris
                SPCA WVS India has been pushing for more sustainable change in
                the fate of these tourist riding horses by requesting the
                authorities to ban the use of ex-racehorses in tourist riding
                use.
              </Text>
            </Box>
          }
        />
      </Content>
      <Content>
        <H2>How you can help</H2>
        <Text>
          Support from individuals like you is very important to help us to
          carry on with our work.
        </Text>
        <Text>
          All donations to WVS India are income-tax deductible under the 80G
          Indian income tax Act. There are also many ways you can help by
          donating useful supplies, for example; food for the dogs, blankets,
          towels, feeding bowls, monthly diesel allowance for our dog catching
          vehicle, medicine and surgical materials, instruments, furniture to
          the staff quarters, stationary/office supplies, new shoes for our
          staff...
        </Text>
        <Text>
          WVS India accounts are audited by John Mathews & Co, chartered
          accountants.
        </Text>
        <InlineLink
          href={WVSForeignAccounts2223}
          title="2022-2023 Audited accounts"
        >
          2022-2023 audited accounts
        </InlineLink>
        <br />
        <InlineLink
          href={WVSForeignAccounts2122}
          title="2021-2022 Audited accounts"
        >
          2021-2022 audited accounts
        </InlineLink>
        <br />
        <InlineLink
          href={WVSForeignAccounts2021}
          title="2020-2021 Audited accounts"
        >
          2020-2021 audited accounts
        </InlineLink>
        <br />
        <InlineLink
          href={WVSForeignAccounts1920}
          title="2019-2020 Audited accounts"
        >
          2019-2020 audited accounts
        </InlineLink>
        <br />
        <InlineLink
          href={WVSForeignAccounts1819}
          title="2018-2019 Audited accounts"
        >
          2018-2019 audited accounts
        </InlineLink>
        <br />
        <InlineLink
          href={WVSForeignAccounts1718}
          title="2017-2018 Audited accounts"
        >
          2017-2018 audited accounts
        </InlineLink>
        <br />
        <InlineLink
          href={WVSForeignAccounts1617}
          title="2016-2017 Audited accounts"
        >
          2016-2017 audited accounts
        </InlineLink>
      </Content>
      <ContentLinks />
    </Layout>
  );
};

export default Index;

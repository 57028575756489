import React from "react";
import * as Styles from "./two-columns.styles";

export const TwoColumns = (props) => {
  return (
    <Styles.Wrapper>
      <Styles.Column>{props.renderLeft}</Styles.Column>
      <Styles.Column>{props.renderRight}</Styles.Column>
    </Styles.Wrapper>
  );
};

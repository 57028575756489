import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  ${({ variant }) =>
    css({
      backgroundColor: variant ? `background.${variant}` : "background.primary",
      color: variant ? `foreground.${variant}` : "foreground.primary",
      borderRadius: [4],
      boxShadow: "softGlow",
      padding: [3],
    })}
`;

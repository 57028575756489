import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  display: flex;

  ${css({
    flexDirection: ["column", "column", "row"],
  })}
`;

export const Column = styled.div`
  flex: 1;
`;
